import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";


export const headers  = {
    "Accept": "application/json",
    "Authorization": "Bearer " + JwtService.getToken()
  };
export const token =   JwtService.getToken()

export const headersMultipart = {
    "Accept": "application/json",    
    "Content-Type": "multipart/form-data",
    "Authorization": "Bearer " +JwtService.getToken()
}