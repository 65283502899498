import { createApp } from "vue";
import App from "./App.vue";
import Vuelidate from "vuelidate";
import Vue from "vue";



/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/prismjs";


const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(Vuelidate);

router.beforeEach(async (to, from, next) => {
   /* if (store.state.accessToken) {
      await store.dispatch('validateToken');
    }*/
    let permisos : Number[] = [];
    if (to.meta.requiresAdministrador) {
      permisos.push(1);
    }
    if (to.meta.requiresComprador) {
      permisos.push(2);
    }
    if (to.meta.requiresEmpresario) {
      permisos.push(3);
    }
    if (to.meta.requiresComercial) {
      permisos.push(4);
    }
    if (to.meta.requiresPuntodeVenta) {
      permisos.push(5);
    }
    if (to.meta.requiresJefeComercial) {
      permisos.push(6);
    }
    if (to.meta.requiresJefedePuntosdeVenta) {
      permisos.push(7);
    }
    if (to.meta.requiresFinanzas) {
      permisos.push(8);
    }
    if (to.meta.requiresAnalista) {
      permisos.push(9);
    }
    if (to.meta.requiresGerencia) {
      permisos.push(10);
    }
    if (to.meta.requiresEmpresario11) {
      permisos.push(11);
    }
    if (permisos.length == 0) {
        next();
    } else {
        if (store.state.accessToken === null || store.state.accessToken == '' || store.state.accessToken == undefined) {
          next('/');
        } else {
          var cont = false
          for (var i = 0; i < permisos.length; i++) {
            if (localStorage.user_rol == permisos[i]) {
              cont = true;
            }
          }
          if (cont) {
            next();
          } else {
            next('/');
          }
        }
    }
  });

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
