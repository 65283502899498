import state from './state'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

const moduloPalco={
    namespaced:true,
    state,
    getters,
    mutations,
    actions
}

export default moduloPalco