import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ThemeModeModule from "@/store/modules/ThemeModeModule";
import moduloPalco from "@/views/palco/storePalco";
import ModuloAforo from "@/views/aforos/storeAforo";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    ConfigModule,
    ThemeModeModule,
    moduloPalco,
    ModuloAforo,
  },
  state:{
    accessToken: 'ewtwe2re5t1re5g15r',
    loggingIn: false,
    loginError: null,
    loader: true,
    validateToken: null,
    tokenResetPassword: null,
    modalLogin: false,
    modalRegister: false,
    tokenActiveAccount: null,
    sha: null,
    obtener_refventa: null,
    init_point: null,
    modalFactura: false,
    idevento : 0,
  }
});

export default store;
