import api from "@/api/configApi";
import {headers} from "@/api/headers";

export const listareventosactivos = async({commit}) => {

    const eventos = await  api.get('listarEventosActivos',{headers:headers})

    const eventosactivos = eventos.data.data
    commit ('setEventosActivos', eventosactivos)

}

export const listareventosexhibicion = async({commit}) => {

    const eventos = await  api.get('listarEventosExhibicion',{headers:headers})

    const eventosactivosexhibicion = eventos.data.data
    commit ('setEventosActivosExhibicion', eventosactivosexhibicion)

}